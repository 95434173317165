<template>

  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">

    <section class="content-header p-1 px-2">
      <div class=" div-header container-fluid">
        <div style="border-bottom: 1px solid #0001" class="row mb-2">
          <div class="col-sm-12 d-flex justify-content-between align-items-center">
            <!-- <span></span> -->
            <h4 style="color: #0007" class="mt-3">
              <i class="fa fa-user-edit mx-1"></i>
              Editar Usuário
            </h4>
            <router-link to="/admin/users" class="btn btn-outline-secondary btn-sm"
                         title="voltar">
              <i class="fa fa-arrow-left mx-1"></i>Voltar
            </router-link>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>

    <section class="content">
      <div class="row " id="NRC">
        <div class="d-flex col-12 mt-4">
          <div class="label d-flex justify-content-end col-lg-3">
            <label class="word-custom" for="name">Nome</label>
          </div>
          <div class="form-group col-lg-6">
            <input
                type="text"
                id="name"
                name="name"
                class="form-control"
                placeholder="Nome..."
                v-model="admin.name"
            />
          </div>
        </div>

        <div class="d-flex col-12">
          <div class="label d-flex justify-content-end col-lg-3">
            <label class="word-custom">Perfil</label>
          </div>
          <div class="form-group col-lg-6">
            <select
                type="text"
                id="perfil"
                name="perfil"
                class="form-control"  
                v-model="admin.role"
            >
              <option v-for="(item, index) in roles" :value="item" :key="index" :selected="item == admin.role">{{ roles_names[index] }}</option>
            </select>
          </div>
        </div>

        <div v-if="
          ['admin'].includes($store.state.admin.perfil) &&
          $store.state.admin.is_admin
        " class="d-flex col-10">
          <div class="label d-flex justify-content-end col-lg-4">
            <label class="word-custom" for="is_admin"></label>
          </div>
          <div class="form-group col-lg-4">
            <input
              id="is_admin"
              type="checkbox"
              v-model="admin.is_admin"
            />
            <span class="word-custom-radio"> Habilitar acesso master.</span>
          </div>
        </div>
        <div class="d-flex col-12">
          <div class="label d-flex justify-content-end col-lg-3">
            <label class="word-custom">Permissões</label>
          </div>
          <div class="form-group col-lg-6">
            <div class="form-check form-switch">
             <div class="custom-control custom-switch">
              <input type="checkbox"  v-model="admin.associated_seller" class="custom-control-input" id="associatedSeller">
              <label class="custom-control-label" for="associatedSeller">Vendedor habilitado a realizar vendas associadas</label>            
          </div>
          </div>
          </div>
        </div>
        <div class="d-flex col-12">
          <div class="label d-flex justify-content-end col-lg-3">
            <label class="word-custom">Email</label>
          </div>
          <div class="form-group col-lg-6">
            <input
                type="email"
                id="email"
                name="email"
                class="form-control"
                placeholder="Email..."
                v-model="admin.email"
            />
          </div>
        </div>

        <div class="d-flex col-12">
          <div class="label d-flex justify-content-end col-lg-3">
            <label class="word-custom">Senha</label>
          </div>
          <div class="form-group col-lg-6">
            <input
                type="password"
                name="password"
                id="password"
                class="form-control"
                placeholder="Senha..."
                v-model="admin.password"
            />
          </div>
        </div>

        <div class="d-flex col-12">
          <div class="label d-flex justify-content-end col-lg-3">
            <label class="word-custom">Confirmar Senha</label>
          </div>
          <div class="form-group col-lg-6">
            <input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                class="form-control"
                placeholder="Confirmar Senha..."
                v-model="admin.confirmPassword"
            />
          </div>
        </div>
      </div>
      <hr/>
      <div class="row">
        <div class="col-lg-12  d-flex justify-content-center ">
          <button class="btn-big btn btn-default" @click="clear()">Limpar</button>
          <button class="btn-big btn btn-success ml-3"
                  @click="update()">Atualizar
          </button>
        </div>
      </div>
    </section>


  </div>
  <!-- /.content-wrapper -->
</template>

<script>

export default {
  data() {
    return {
      admin: {
        associated_seller: false,
        associated_seller_email: "",
      },
      nameAdmin: '',
      integratedEmail: '',
      roles_names: ['Administrator', 'Vendedor', 'Autor', 'Suporte', 'Financeiro'],
      roles: ['admin', 'seller', 'author', 'support', 'finance'],
    }
  },
  //remover esse watch depois da virada
  watch: {
    'admin.associated_seller'(){
      if (this.admin.associated_seller === false) {
        this.admin.associated_seller_email = "";
      }
    },
    'admin.email'(value){
        this.autoCompleteEmailRdStation(value)
    },
    integratedEmail(email){
      this.admin.associated_seller_email = email
      
    }
  },
  methods: {
    //remover esse metodo depois da virada
    autoCompleteEmailRdStation(value){
      const email = value;
      const strangeEmail = email.replace(/([^@]+)@/, '$1+rdstation@');
      if(this.admin.associated_seller === true){
        this.integratedEmail = strangeEmail
      }
    },
    getManager() {
      this.$tallos_admin_api.defaults.headers.common["Authorization"] = `Bearer ${this.$store.getters.tokenJWT}`;

      this.$tallos_admin_api.get(`auth/manager/${this.$route.params.id}`,)
          .then(resp => {
            this.admin = resp.data
            this.nameAdmin = resp.data.name
          })
          .catch(e => {
            this.$toasted.global.defaultError({msg: e.response.data})
          })
    },

    update() {
      this.$tallos_admin_api.defaults.headers.common["Authorization"] =
          `Bearer ${this.$store.getters.tokenJWT}`;
      this.$tallos_admin_api.put(`auth/manager/${this.$route.params.id}`, {
          creator: this.$store.state.admin.name,
          ...this.admin
        })
          .then(resp => {
            this.$toasted.global.defaultSuccess({msg: `Usuário Atualizado.`})
            this.$router.push('/admin/users')
          })
          .catch(e => {
            if (e.response.data.error) {
              if (e.response.data.error.code == 11000) {
                this.$toasted.global.defaultError({msg: 'Email já existe!'})
                return false
              }
            }
            this.$toasted.global.defaultError({msg: e.response.data})
          })
    },

    clear() {
      this.admin = {}
    }
  },

  created() {
    this.getManager()
    document.title = 'Tallos Usuários - Editar Usuário'
  }
}
</script>

<style scoped>

.content-wrapper {
  background-color: #fff;
}

.btn-big {
  width: 180px;
  padding: 8px;
}

</style>